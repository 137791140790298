import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "../assets/logo.png";
import "../style/navbar.css";
import { Link as ScrollLink } from "react-scroll";
import trackOnce from "../utils/trackOnce"; // Import the tracking function

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();

  // Track page views on location change
  useEffect(() => {
    trackOnce("page_view", {
      page_path: location.pathname,
      page_title: "Navbar Navigation",
    });
  }, [location.pathname]);

  const handleToggle = () => {
    setIsMobile(!isMobile);
  };

  const handleLinkClick = (label) => {
    setIsMobile(false);
    trackOnce("cta_click", {
      category: "Navigation",
      action: "Click",
      label: label,
    });
  };

  const isHomePage = location.pathname === "/";

  const handleDocumentClick = () => {
    trackOnce("cta_click", {
      category: "Navigation",
      action: "Click",
      label: "Documente Dropdown",
    });
    if (window.innerWidth >= 768) {
      setIsDropdownOpen((prev) => !prev);
    } else {
      setIsMobile(false);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".dropdown")) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        {isHomePage ? (
          <ScrollLink
            className="button-navbar"
            to="home"
            smooth={true}
            duration={500}
            onClick={() => handleLinkClick("Home Logo")}>
            <img className="image-logo" src={Logo} alt="eliberariacte.ro" />
          </ScrollLink>
        ) : (
          <NavLink
            className="button-navbar"
            to="/"
            onClick={() => handleLinkClick("Home Logo")}>
            <img className="image-logo" src={Logo} alt="eliberariacte.ro" />
          </NavLink>
        )}
      </div>

      <ul className={`nav-links ${isMobile ? "nav-links-mobile open" : ""}`}>
        <li>
          {isHomePage ? (
            <ScrollLink
              className="button-navbar"
              to="home"
              onClick={() => handleLinkClick("Acasă")}>
              Acasă
            </ScrollLink>
          ) : (
            <NavLink
              className="button-navbar"
              to="/"
              onClick={() => handleLinkClick("Acasă")}>
              Acasă
            </NavLink>
          )}
        </li>
        <li className="dropdown">
          {isHomePage ? (
            <ScrollLink
              className="button-navbar"
              to="services"
              onClick={handleDocumentClick}>
              Documente
            </ScrollLink>
          ) : (
            <span className="button-navbar" onClick={handleDocumentClick}>
              Documente
            </span>
          )}
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <NavLink
                to="cazier-judiciar"
                className="dropdown-item"
                onClick={() => handleLinkClick("Cazier Judiciar")}>
                Cazier
              </NavLink>
              <NavLink
                to="certificat-constatator"
                className="dropdown-item"
                onClick={() => handleLinkClick("Certificat Constatator")}>
                Certificat Constatator
              </NavLink>
              <NavLink
                to="extras-plan-cadastral"
                className="dropdown-item"
                onClick={() => handleLinkClick("Plan Cadastral")}>
                Plan Cadastral
              </NavLink>
              <NavLink
                to="extras-carte-funciara"
                className="dropdown-item"
                onClick={() => handleLinkClick("Carte Funciară")}>
                Carte Funciară
              </NavLink>
            </div>
          )}
        </li>
        <li>
          {isHomePage ? (
            <ScrollLink
              className="button-navbar"
              to="contact"
              onClick={() => handleLinkClick("Contact")}>
              Contact
            </ScrollLink>
          ) : (
            <NavLink
              className="button-navbar"
              to="/#contact"
              onClick={() => handleLinkClick("Contact")}>
              Contact
            </NavLink>
          )}
        </li>
      </ul>

      <div className="left-side-navbar">
        <ScrollLink
          className="btn"
          to="services"
          onClick={() => handleLinkClick("Obține Document")}>
          OBȚINE DOCUMENT
        </ScrollLink>

        <div className="hamburger" onClick={handleToggle}>
          {isMobile ? (
            <FaTimes size={30} className="hamburger-icon" />
          ) : (
            <FaBars size={30} className="hamburger-icon" />
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
