import React from "react";
import "../style/Confirmare.css";
import { Link } from "react-router-dom";

const Confirmare = () => {
  return (
    <div className="confirmation-container">
      <div className="confirmation-icon">✔️</div>
      <h1>Plata a fost realizată cu succes!</h1>
      <p>Veți primi documentele solicitate în cel mai scurt timp.</p>
      <Link to="/" className="confirmation-button">
        Înapoi la pagina principală
      </Link>
    </div>
  );
};

export default Confirmare;
