import React from "react";
import "../style/Fail.css";
import { Link } from "react-router-dom";

const Fail = () => {
  return (
    <div className="fail-container">
      <div className="fail-icon">❌</div>
      <h1>Plata nu a fost realizată!</h1>
      <p>
        A apărut o problemă la procesarea plății. Vă rugăm să încercați din nou
        sau să ne contactați pentru asistență.
      </p>
      <Link to="/" className="fail-button">
        Înapoi la pagina principală
      </Link>
    </div>
  );
};

export default Fail;
