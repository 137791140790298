import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/logo-footer.png"; // Update with the correct path to the logo image
import "../style/footer.css";
import UPVM from "../assets/upvm.png";
import TermeniSiConditii from "../pages/TermeniSiConditii";
import PoliticaDeConfidentialitate from "../pages/PoliticaDeConfidentialitate";
import CookiePolicy from "../pages/CookiePolicy";
import GDPR from "../pages/GDPR";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isCookieModalOpen, setIsCookieModalOpen] = useState(false);
  const [isGDPRModalOpen, setIsGDPRModalOpen] = useState(false);

  const isHomePage = location.pathname === "/";

  const handleNavLinkClick = (section) => {
    if (isHomePage) {
      // Scroll to section on the homepage
      setTimeout(() => {
        const element = document.getElementById(section);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    } else {
      // Navigate to homepage, then scroll
      navigate("/");
      setTimeout(() => {
        const element = document.getElementById(section);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const togglePrivacyModal = () => {
    setIsPrivacyModalOpen(!isPrivacyModalOpen);
  };

  const toggleCookieModal = () => {
    setIsCookieModalOpen(!isCookieModalOpen);
  };

  const toggleGDPRModal = () => {
    setIsGDPRModalOpen(!isGDPRModalOpen);
  };

  return (
    <>
      <footer className="footer">
        <div className="footer-top">
          <div className="footer-left">
            <img
              src={logo}
              alt="EliberariActe.ro Logo"
              className="footer-logo"
              onClick={() => handleNavLinkClick("home")}
              style={{ cursor: "pointer" }}
            />
          </div>

          <div className="footer-right">
            <span
              onClick={() => handleNavLinkClick("home")}
              className="footer-link"
              style={{ cursor: "pointer" }}>
              Acasă
            </span>
            <span
              onClick={() => navigate("/despre-eliberariacte")}
              className="footer-link"
              style={{ cursor: "pointer" }}>
              Despre
            </span>

            <span
              onClick={() => handleNavLinkClick("contact")}
              className="footer-link"
              style={{ cursor: "pointer" }}>
              Contact
            </span>
            <span
              onClick={toggleModal}
              className="footer-link"
              style={{ cursor: "pointer" }}>
              Termeni și Condiții
            </span>
            <span
              onClick={togglePrivacyModal}
              className="footer-link"
              style={{ cursor: "pointer" }}>
              Politica de Confidențialitate
            </span>
            <span
              onClick={toggleCookieModal}
              className="footer-link"
              style={{ cursor: "pointer" }}>
              Politica de Cookie-uri
            </span>
            <span
              onClick={toggleGDPRModal}
              className="footer-link"
              style={{ cursor: "pointer" }}>
              GDPR
            </span>

            <div className="anpc">
              <a
                href="https://anpc.ro/ce-este-sal/"
                target="_blank"
                rel="noreferrer">
                <img
                  style={{ width: "200px", margin: "5px" }}
                  loading="lazy"
                  src="https://wpfitness.eu/wp-content/uploads/2022/10/anpc-sal.png"
                  alt="Solutionarea Alternativa a Litigiilor"
                />
              </a>
              <br />
              <a
                href="https://ec.europa.eu/consumers/odr"
                target="_blank"
                rel="noreferrer">
                <img
                  style={{ width: "200px", margin: "5px" }}
                  loading="lazy"
                  src="https://wpfitness.eu/wp-content/uploads/2022/10/anpc-sol.png"
                  alt="Solutionarea Online a Litigiilor"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <a
            className="anchor-upvm"
            style={{ textDecoration: "none", color: "#000000" }}
            href="http://upvisionmedia.com"
            target="blank">
            creat de
            <img
              className="upvm-logo"
              loading="lazy"
              src={UPVM}
              alt="upvisionmedia creare site-uri web personalizate"
            />
          </a>
        </div>
      </footer>

      {isModalOpen && (
        <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
          <div
            className="modal-content-terms"
            onClick={(e) => e.stopPropagation()}>
            <button
              className="close-modal"
              onClick={() => setIsModalOpen(false)}>
              &times;
            </button>
            <TermeniSiConditii />
          </div>
        </div>
      )}
      {isPrivacyModalOpen && (
        <div
          className="modal-overlay"
          onClick={() => setIsPrivacyModalOpen(false)}>
          <div
            className="modal-content-privacy"
            onClick={(e) => e.stopPropagation()}>
            <button
              className="close-modal"
              onClick={() => setIsPrivacyModalOpen(false)}>
              &times;
            </button>
            <PoliticaDeConfidentialitate />
          </div>
        </div>
      )}
      {isCookieModalOpen && (
        <div
          className="modal-overlay"
          onClick={() => setIsCookieModalOpen(false)}>
          <div
            className="modal-content-cookies"
            onClick={(e) => e.stopPropagation()}>
            <button
              className="close-modal"
              onClick={() => setIsCookieModalOpen(false)}>
              &times;
            </button>
            <CookiePolicy />
          </div>
        </div>
      )}

      {isGDPRModalOpen && (
        <div
          className="modal-overlay"
          onClick={() => setIsGDPRModalOpen(false)}>
          <div
            className="modal-content-gdpr"
            onClick={(e) => e.stopPropagation()}>
            <button
              className="close-modal"
              onClick={() => setIsGDPRModalOpen(false)}>
              &times;
            </button>
            <GDPR />
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
