import React, { useState, useEffect, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import WhatsAppChat from "./components/WhatsAppChat";
import { Element } from "react-scroll";
import Confirmare from "./pages/Confirmare";
import Fail from "./pages/Fail";

// Lazy load pentru pagini și componente
const Home = React.lazy(() => import("./pages/Home"));
const Services = React.lazy(() => import("./pages/Services"));
const Contact = React.lazy(() => import("./pages/Contact"));
const CazierJudiciar = React.lazy(() => import("./pages/CazierJudiciar"));
const CertificatConstatator = React.lazy(() =>
  import("./pages/CertificatConstatator")
);
const ExtrasPlanCadastral = React.lazy(() =>
  import("./pages/ExtrasPlanCadastral")
);
const ExtrasCarteFunciara = React.lazy(() =>
  import("./pages/ExtrasCarteFunciara")
);
const TermeniSiConditii = React.lazy(() => import("./pages/TermeniSiConditii"));
const PoliticaDeConfidentialitate = React.lazy(() =>
  import("./pages/PoliticaDeConfidentialitate")
);
const GDPR = React.lazy(() => import("./pages/GDPR"));
const Faq = React.lazy(() => import("./pages/Faq"));
const CookiePolicy = React.lazy(() => import("./pages/CookiePolicy"));
const AboutUs = React.lazy(() => import("./pages/About.Us"));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const CookieAlert = ({ togglePrivacyModal }) => {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (!cookiesAccepted) {
      setShowAlert(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setShowAlert(false);
  };

  const handleDecline = () => {
    localStorage.setItem("cookiesAccepted", "false");
    setShowAlert(false);
  };

  if (!showAlert) {
    return null;
  }

  return (
    <div style={alertStyle}>
      <p style={paragraphStyle}>
        Acest site folosește cookie-uri pentru a vă oferi o experiență mai bună.
        Pentru mai multe informații, citiți{" "}
        <span onClick={togglePrivacyModal} style={linkStyle}>
          politica noastră de confidențialitate
        </span>
        . Sunteți de acord cu utilizarea cookie-urilor?
      </p>
      <div style={buttonContainerStyle}>
        <button onClick={handleAccept} style={buttonStyle}>
          Accept
        </button>
        <button onClick={handleDecline} style={buttonStyle}>
          Refuz
        </button>
      </div>
    </div>
  );
};

const alertStyle = {
  position: "fixed",
  bottom: 0,
  width: "100%",
  backgroundColor: "#f8f9fa",
  padding: "10px",
  boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const paragraphStyle = {
  margin: "0 0 10px 0",
  textAlign: "center",
};

const linkStyle = {
  color: "#0056b3",
  textDecoration: "underline",
  cursor: "pointer",
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "center",
  gap: "10px",
  width: "50%",
  flexWrap: "wrap",
};

const buttonStyle = {
  padding: "1% 3%",
  backgroundColor: "#0056b3",
  color: "#ffffff",
  border: "none",
  borderRadius: "3px",
  fontSize: "1rem",
  cursor: "pointer",
};

const MainPage = () => {
  return (
    <>
      <Element name="home">
        <Home />
      </Element>
      <Services />
      <Faq />
      <Element name="contact">
        <Contact />
      </Element>
    </>
  );
};

function App() {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  const togglePrivacyModal = () => {
    setIsPrivacyModalOpen(!isPrivacyModalOpen);
  };

  return (
    <Router>
      <Navbar />
      <ScrollToTop />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<MainPage />} />

          <Route path="/despre-eliberariacte" element={<AboutUs />} />
          <Route path="/cazier-judiciar" element={<CazierJudiciar />} />
          <Route
            path="/certificat-constatator"
            element={<CertificatConstatator />}
          />
          <Route
            path="/extras-plan-cadastral"
            element={<ExtrasPlanCadastral />}
          />
          <Route
            path="/extras-carte-funciara"
            element={<ExtrasCarteFunciara />}
          />
          <Route path="/confirmare" element={<Confirmare />} />
          <Route path="/fail" element={<Fail />} />
          <Route path="/termeni-si-conditii" element={<TermeniSiConditii />} />
          <Route path="/cookies" element={<CookiePolicy />} />
          <Route
            path="/politica-de-confidentialitate"
            element={<PoliticaDeConfidentialitate />}
          />
          <Route path="/gdpr" element={<GDPR />} />
        </Routes>
      </Suspense>
      <Footer />
      <WhatsAppChat />
      <CookieAlert togglePrivacyModal={togglePrivacyModal} />
      {isPrivacyModalOpen && (
        <div className="modal-overlay" onClick={togglePrivacyModal}>
          <div
            className="modal-content-privacy"
            onClick={(e) => e.stopPropagation()}>
            <button className="close-modal" onClick={togglePrivacyModal}>
              &times;
            </button>
            <PoliticaDeConfidentialitate />
          </div>
        </div>
      )}
    </Router>
  );
}

export default App;
