import Cookies from "js-cookie";

/**
 * Trimite un eveniment către Google Analytics doar o dată în intervalul de timp specificat.
 * @param {string} eventName - Numele evenimentului
 * @param {object} eventParams - Parametrii evenimentului
 * @param {number} durationInMinutes - Durata în minute pentru care cookie-ul este valid
 */
const trackOnce = (eventName, eventParams, durationInMinutes = 5) => {
  const cookieName = `event_${eventName}`;
  const cookieExists = Cookies.get(cookieName);

  if (!cookieExists) {
    // Trimite evenimentul către Google Analytics
    if (window.gtag) {
      window.gtag("event", eventName, eventParams);
      console.log(`Event "${eventName}" sent to Google Analytics.`);
    } else {
      console.warn("Google Analytics is not initialized.");
    }

    // Setează cookie-ul pentru durata specificată
    Cookies.set(cookieName, "true", { expires: durationInMinutes / 1440 }); // Conversia în zile
  } else {
    console.log(`Event "${eventName}" was already sent recently. Skipping.`);
  }
};

export default trackOnce;
